@use 'fonts';
@use 'variables';
@import '/node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '/node_modules/bootstrap53/scss/mixins/breakpoints';
@import '/node_modules/bootstrap53/scss/functions';
@import '/node_modules/bootstrap53/scss/variables';
@import '/node_modules/bootstrap53/scss/mixins';
// @import '/node_modules/bootstrap/scss/bootstrap.scss';

* {
  font-family: 'Soleil';
}

.btn {
  &:focus {
    outline: none !important;
  }
}

.btn-danger {
  color: #fff !important;
  &:hover,
  :focus {
    background-color: var(--color-red) !important;
    border-color: var(--color-red) !important;
  }
}

.form-control:focus {
  border-color: var(--color-red) !important;
  box-shadow: 0 0 0 0.05rem var(--color-red) !important;
}

.modal-backdrop.fade {
  opacity: 0.5;
}

.fade {
  opacity: 0.5;
}

.clear {
  clear: both;
}

.object-contain {
  object-fit: contain;
}

.invalid-feedback {
  display: block !important;
  font-family: 'Soleil';
  font-size: 13px;
}

.invisible {
  visibility: hidden !important;
}

.micromodal-slide {
  z-index: 2147483647 !important;
}

.leaflet-pane,
.leaflet-control-container,
.leaflet-container {
  z-index: 0 !important;
}

.w-40 {
  width: 40% !important;
}

.btn-secondary {
  background-color: var(--color-semidark);
}

.btn-danger {
  background-color: var(--color-red);
}

html,
body {
  padding: 0px !important;
  margin: 0px !important;
  /* overflow-x: hidden; */
  box-sizing: border-box;
}

.sharpsans-book {
  font-family: SharpSans-book !important;
}

.sharpsans-bold {
  font-family: SharpSans-bold !important;
}

.sharpsans-semi-bold {
  font-family: SharpSans-semi-bold !important;
}

.sharpsans-extra-bold {
  font-family: SharpSans-extra-bold !important;
}

.soleil {
  font-family: Soleil !important;
}

.text-dark-grey {
  color: #555555 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fonts-5 {
  letter-spacing: 0.05em;
}

.fonts-6 {
  letter-spacing: 0.06em;
}

.fonts-7 {
  letter-spacing: 0.07em;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.overflow-y {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.overflow-x {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.overflow {
  overflow: auto !important;
  overflow: auto !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

.text-link {
  cursor: pointer;
}

.text-fluid {
  font-size: 10px;
}

.text-small {
  font-size: 90%;
}

.text-xs {
  font-size: 12px !important;
}

.text-xxs {
  font-size: 9px !important;
}

.text-xxxs {
  font-size: 5px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-md {
  font-size: 24px !important;
}

.text-slmd {
  font-size: 20px !important;
}

.text-ssmd {
  font-size: 18px !important;
}

.text-smd {
  font-size: 16px !important;
}

.text-xmd {
  font-size: 28px !important;
}

.text-lg {
  font-size: 30px !important;
}

.text-xlg {
  font-size: 36px !important;
}

.text-xxl {
  font-size: 42px !important;
}

.text-xxlg {
  font-size: 46px !important;
}

.text-82 {
  font-size: 82px !important;
}

.border-12 {
  border-radius: 12px !important;
  border-width: 0px !important;
}

.border-0 {
  border-radius: 0px !important;
}

.bold {
  font-weight: 1000 !important;
}

.bold-lg {
  font-weight: 1600 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold-sm {
  font-weight: 450 !important;
}

.underlay {
  z-index: -1 !important;
}

.overlay {
  z-index: 999 !important;
}

.text-red {
  color: #d35551 !important;
}

.bg-red {
  background-color: #d35551 !important;
}

.text-grey {
  color: #797979;
}

.text-light-grey {
  color: #707070;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.posfixed {
  position: fixed;
}

.posrelative {
  position: relative;
}

.col-6 :not(.col-12).info-popular {
  position: fixed;
}

a.reset-calendar.pull-right {
  font-weight: bold;
  color: #666 !important;
  margin-bottom: 1em;
  margin-right: 1em;
  text-decoration: underline;
  cursor: pointer;
}

.transfers .autocomplete-container,
.transfers .input-container {
  border-radius: 28px;
  height: 38px;
  border: none !important;
  box-shadow: none !important;
}

.transfers .autocomplete-container .input-container input {
  height: 38px;
  line-height: initial;
  background-color: transparent;
  text-align: center;
}

/* Material cutout for mat-checkbox */

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: #000;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element {
  background: #3f51b5;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: #ff4081;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#mobsortby:not(.collapsed) > #sortby {
  display: block !important;
}

/* V2 hacks */

// main {
//   overflow: visible !important;
// }

main {
  min-height: 100%;
}

.ngb-dp-month {
  margin-top: -1.8rem;

  &:nth-of-type(2) {
    display: none;
  }
}
ngb-popover-window {
  opacity: 1 !important;
}

.popover.show {
  z-index: 999999 !important;
}

.popover-body {
  background-color: var(--color-blue);
}

ngb-datepicker {
  .ngb-dp-navigation-select {
    display: none;
  }

  & * {
    background-color: var(--color-blue) !important;
  }

  .ngb-dp-day {
    font-weight: 600;

    .range {
      display: block;
      width: 30px;
      height: 30px;
      background-color: white !important;
      margin-top: -0.5rem;
      margin-left: -7px;
      overflow: hidden;
      color: var(--color-red);
      border-radius: 50%;
      border: 1px solid var(--color-red);
      line-height: 29px;
    }

    &.hidden,
    &.disabled {
      color: #aaa;
      pointer-events: none;
    }
  }

  .ngb-dp-weekday {
    color: black;
    font-weight: 600;
  }

  .ngb-dp-day,
  .ngb-dp-weekday {
    background-color: var(--color-blue);
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-family: 'Soleil';
    font-size: 14px;
    margin: 1.3rem;
  }

  .ngb-dp-arrow,
  .ngb-dp-arrow-btn,
  .ngb-dp-navigation-chevron {
    color: var(--color-dark);
    font-size: 14px;
    outline: none !important;
    outline-color: transparent !important;
    box-shadow: none !important;
  }

  .ngb-dp-month-name {
    font-size: 15px;
    color: var(--color-dark);
    font-family: 'Soleil';
  }

  ngb-datepicker-month {
    margin-top: 1.5rem;
  }

  ngb-datepicker-navigation {
    * {
      font-family: 'Soleil';
      font-size: 15px;
    }
  }

  *[aria-label^='Saturday'] {
    &:not(:has(.range)) {
      white-space: nowrap;

      &::after {
        content: '*';
      }
    }
  }
}

#gallery-modal {
  .carousel-inner {
    width: 100vw;
    height: 90vh;

    .carousel-item {
      float: none;
      text-align: center;
      height: 90vh;
      width: 100vw;
      transition: none;
    }
    & > .active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modal__close {
  background: transparent;
  border: 0;

  &:focus {
    border: none;
    box-shadow: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
  }
}

.modal__header .modal__close:before {
  content: '\2715';
  font-size: 20px;
  font-weight: bold;
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font: normal normal normal 15px/19px Soleil;
  letter-spacing: 0px;
  color: var(--color-dark);

  & > * {
    line-height: 1.8;
  }
}

.transparent {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

@include media-breakpoint-only(xxl) {
  main {
    overflow: visible !important;
  }

  body {
    .whereBox,
    .whenBox,
    .occupancyBox {
      &.fade {
        opacity: 1 !important;
        max-width: 710px;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

@include media-breakpoint-down(xxl) {
  div#live-chat-widget {
    bottom: 50px !important;
  }

  body {
    .popover {
      max-width: 90%;
    }
  }
}

@media (max-width: 380px) {
  .ngb-dp-day,
  .ngb-dp-weekday {
    margin: 0.7rem !important;
  }
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 999;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.search .woot-widget-bubble,
.widget-bubble {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 820px) {
  .search .woot-widget-bubble {
    margin-bottom: 48px !important;
  }
}

.user-custom-phone-control {
  .iti {
    width: 100%;
  }
  .dropdown-menu {
    font-size: 14px !important;
  }
  .custom-phone-control {
    background: #ffffff;
    border: 1px solid #f0f0f0 !important;
    color: var(--color-semidark);
    font: 15px/19px Soleil;
    letter-spacing: -0.24px;
    border: none;
    box-shadow: none;
    height: 52px;

    &.invalid {
      border: 1px solid red;
    }
  }
}

.custom-anchor {
  color: var(--color-red) !important;
  &:hover {
    color: var(--color-red) !important;
  }
}

.transfer-panel {
  margin-top: 2rem;
  .mat-expansion-panel-header-title {
    padding-top: 5px !important;
  }
  .transfer-panel-header {
    height: 60px;
    background-image: url('/assets/oss/bg-transfers.png') !important;
    background-size: cover !important;
    background-position: center !important;
    .extra-name {
      color: white !important;
      font-size: 28px;
      text-transform: uppercase;
    }
    i {
      padding-top: 5px;
      color: white !important;
    }
    .mat-content {
      align-items: center !important;
    }
  }
  .mat-expansion-panel-body {
    padding: 0px !important;
    .transfer-panel-body {
      margin-top: 15px;
      background-color: var(--color-grey);
      min-height: 200px;
      padding: 10px 0px;
      .box-panel {
        position: relative;
        background-color: #ffffff;
        border-radius: 5px;
        height: 50px;
        &::after {
          position: relative;
          top: 45%;
          font-size: 24px;
        }
        label {
          align-items: center;
          display: flex;
          font-size: 16px;
          font-weight: 500;
          padding: 12px;
          margin-bottom: 0;
          color: var(--color-semidark);
        }
      }
      .dropdown-menu.show {
        width: 85% !important;
      }

      .custom-dropdown {
        display: flex;
        align-items: center;
        justify-content: center;

        .custom-select {
          border: none;
          padding: 0px 20px;
          appearance: none;
          background-color: #fff;
          background-image: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>");
          background-repeat: no-repeat;
          background-position: right 20px center;
          background-size: 16px;
          width: 100%;
          height: 50px;
          border-radius: 25px;
          font-size: 20px;
          font-weight: 500;

          &:focus,
          &:focus-visible {
            border-color: #ccc;
            border: none !important;
            box-shadow: none; // Removes Bootstrap's default blue box-shadow on focus
          }

          // &::after {
          //   content: attr(data-display);
          //   position: absolute;
          //   top: 50%;
          //   left: 10px;
          //   transform: translateY(-50%);
          //   color: #333; // Change to your desired font color
          //   font-weight: bold; // Bold font weight
          //   pointer-events: none;
          // }

          option:checked,
          option:selected {
            font-weight: bold;
            background-color: #ebebeb; // Background color for the selected option
          }
        }
      }
      .box-panel-transfers {
        position: relative;
        background-color: #fff;
        border-radius: 25px;
        height: 80px;
        i {
          font-size: 20px;
        }
        label {
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 16px;
          color: var(--color-red);
          font-weight: 500;
          padding: 0px 12px;
          margin-bottom: 0;
        }
        .custom-select {
          padding: 0px 10px;
          background-image: none !important;
          background: none !important;
          height: 25px;
        }
      }

      .search-buttom {
        margin: 0px 15px 10px;
        button {
          height: 50px;
          text-transform: uppercase;
          font-weight: 500;
        }
      }

      .type-ahead-input {
        .mat-mdc-form-field-subscript-wrapper {
          display: none !important;
        }
        .mdc-floating-label {
          will-change: auto !important;
          cursor: pointer !important;
        }
        .mdc-floating-label--required::after {
          content: '' !important;
        }
        .form-control {
          box-shadow: none !important;
        }
      }

      .input-group {
        min-height: 50px;
      }
    }
    .quote-body {
      margin: 20px;
      .card-option {
        border: 1px solid #fff;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 5px;
        display: flex;
        background: #fff;
        flex-wrap: wrap;
        .card-body {
          p {
            display: inline;
          }
        }
      }
      .icon-container {
        width: 50px;
        height: 50px;
        margin-right: 20px;
        object-fit: cover;
        .icon-image {
          width: 100%;
          height: 100%;
        }
      }

      .price p {
        font-weight: bold;
      }
      .btn {
        &:focus {
          outline: none !important;
        }
      }
      .btn-outline-danger {
        border-color: var(--color-red) !important;
        &:hover {
          color: #fff !important;
        }
      }
      .btn-danger {
        color: #fff !important;
        &:hover {
          background-color: var(--color-red) !important;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .transfer-panel-body {
    .custom-dropdown {
      .custom-select {
        background-position: right 80px center !important;
        padding: 0px 50px !important;
      }
    }
  }
}

.mat-mdc-select {
  background: #fff !important;
  height: 50px !important;
  border-radius: 25px !important;
  font-size: 20px !important;
  .mat-mdc-select-trigger {
    padding: 15px !important;
    font-weight: 500 !important;
    .mat-mdc-select-value {
      padding-left: 10px;
    }
  }
}

.mat-calendar-body-selected {
  background-color: var(--color-red) !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .cdk-overlay-pane.mat-datepicker-popup {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 0px !important;
    left: 0px !important;
  }
}
.custom-input-group-text-container {
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 24px !important;
    height: 20px !important;
    padding: 0px !important;
  }
}

.custom-transfer-selection {
  .btn {
    &:focus {
      outline: none !important;
    }
  }
  button {
    width: 100px;
    text-transform: uppercase !important;
    border: 0.5px solid var(--color-red) !important;
    height: 40px !important;
    font-weight: 500;
    &:hover {
      color: #ffffff !important;
    }
  }
}
.mat-mdc-radio-button.mat-accent[_ngcontent-fhe-c161] {
  --mdc-radio-selected-focus-icon-color: var(--color-red) !important;
  --mdc-radio-selected-hover-icon-color: var(--color-red) !important;
  --mdc-radio-selected-icon-color: var(--color-red) !important;
  --mdc-radio-selected-pressed-icon-color: var(--color-red) !important;
  --mat-mdc-radio-checked-ripple-color: var(--color-red) !important;
}

.custom-form-field {
  border: none !important;
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch {
    border: none !important;
  }
  .mdc-text-field--invalid:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch {
    border: none !important;
  }
  .mdc-text-field--outlined .mdc-notched-outline {
    cursor: pointer !important;
    div {
      border: none !important;
      width: calc(100% / 3) !important;
      cursor: pointer;
    }
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding-top: 24px;
    padding-bottom: 0px;
  }
}

ngb-typeahead-window {
  padding: 0px !important;
}

.transfer-search-form {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;

  .form-group {
    margin-bottom: 15px;

    input,
    select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-sizing: border-box;

      &:focus {
        outline: none;
        border-color: var(--color-red);
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      // background: url('path-to-down-arrow-icon') no-repeat right;
      // background-size: 12px;
    }
  }

  .date-picker {
    display: flex;
    justify-content: space-between;

    input {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .transfer-search-btn {
    width: 100%;
    padding: 15px 0;
    border: none;
    background-color: var(--color-red);
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    &:focus {
      outline: none !important;
    }
    &:hover {
      background-color: var(--color-red);
    }
  }

  .input-group-lg > .form-control {
    height: 55px !important;
  }
}
@media screen and (min-width: 768px) {
  .show-desktop-search-layout {
    display: block;
  }

  .show-mobile-search-layout {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .show-desktop-search-layout {
    display: none;
  }

  .show-mobile-search-layout {
    display: block;
  }
  .cdk-overlay-pane.mat-datepicker-popup {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .duration-dropdown {
    min-width: none !important;
    text-align: center;
  }
}

.z-index-1 {
  z-index: 1 !important;
}

.custom-h-50 {
  height: 50px !important;
}

.custom-buttom {
  width: 250px !important;
}

@media screen and (min-width: 420px) {
  .custom-buttom {
    width: 300px !important;
  }
}

.continue-btn-wrapper {
  display: flex;

  button {
    width: 100%;
    height: 64px;
    border-radius: 8px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    font: normal normal 600 18px/17px SharpSans-book;
    justify-content: end;
    position: relative;

    .continue-text {
      width: 100%;
      position: absolute;
      margin: 0 auto;
      left: 50%;
      transform: translate(-50%, 0);
    }

    p {
      margin: 0;
      text-transform: uppercase;
    }
  }
}

.transfer-details {
  padding: 15px;
  background-color: #ffffff;
  .transfer-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .transfer-column {
    flex-basis: 33.3333%;
  }

  .transfer-info {
    font-size: 1.25rem;
  }
}

.custom-row {
  div {
    img {
      max-width: 100%;
    }
  }
}

.hover-pointer {
  cursor: pointer !important;
}

.transfer-remove-text {
  padding-bottom: 1.25rem !important;
  font-size: 14px !important;
  text-align: start;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem !important;
  i {
    cursor: pointer;
    padding: 5px;
    font-size: 20px;
    color: var(--color-semidark);
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 4px;
    }
  }
  span {
    padding: 0 10px;
    font-size: 20px;
  }
}

.pass-panel {
  .transfer-panel-header {
    background-image: url('/assets/oss/bg-pass.png') !important;
  }
  .mat-expansion-panel-body {
    padding: 0px !important;
    .pass-panel-body {
      margin-top: 15px;
      min-height: 200px;
      padding: 10px 0px;
      color: #858484 !important;
      .custom-transfer-selection {
        .text-custom-dark {
          background: #f1efee;
          color: #2c2a2a !important;
        }
        .text-normal {
          color: #ffffff;
        }
        button {
          width: auto !important;
          border: none !important;
        }
      }
      .duration-label {
        font-size: 18px;
        font-weight: 700;
      }
      .duration-dropdown {
        min-width: 200px;
        text-align: center;
      }

      .pass-duration {
        width: 200px;
        select {
          text-align: center !important;
        }
      }
      .pass-item-list {
        .pass-item {
          height: 50px;
          border: 1px solid #f2f4f5;
          border-radius: 15px;
          color: #858484 !important;
          .form-check-input {
            width: 20px;
            height: 20px;
          }
          .counter {
            span {
              font-size: 24px !important;
              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
                border-radius: 4px;
              }
            }
            .counter-count {
              background-color: #d9d9d9;
              &:hover {
                background-color: none;
                border-radius: none;
              }
            }
          }
        }
      }
    }
  }
}
.stripe-error.alert-danger {
  font-size: 16px;
  background: var(--color-red) !important;
  color: var(--color-light) !important;
  .material-symbols-outlined {
    cursor: pointer;
  }
}